import { useMutation } from "@tanstack/react-query";
import { UserContext } from "App";
import { createUpdateTenant } from "data/queries";
import { useTenant } from "hooks/use-tenant";
import { Toast, useToast } from "components/toast";
import React, { useContext, useEffect, useState } from "react";

export const TenantDomains = () => {
  const [{ token }] = useContext(UserContext);
  const { tenant, data: tenantData } = useTenant();
  const [allowedDomains, setAllowedDomains] = useState([]);

  const { isVisible, message, type, showToast } = useToast();
  const { mutate: updateTenant } = useMutation({
    mutationFn: createUpdateTenant({ token }),
    mutationKey: ["createUpdateTenant"],
    onError: ({ message }) => showToast(message, "error"),
    onSuccess: () => {
      showToast("Domains saved successfully", "success");
    },
  });

  useEffect(() => {
    if (tenantData?.allowedDomains?.length) {
      setAllowedDomains(tenantData.allowedDomains);
    }
  }, [tenantData]);

  if (!token || !tenantData) return null;

  return (
    <div className="card w-full bg-base-300 text-base-content">
      <div className="card-body ">
        <h2 className="card-title">Allowed Domains</h2>

        {/* eslint-disable-next-line */}
        <div className="bg-base-100 rounded-xl p-4 border input-bordered">
          <label className="flex gap-2">
            <span className="">Domains:</span>
            <textarea
              onChange={(e) => {
                const domains = e.target.value.split(",");
                setAllowedDomains(domains);
              }}
              value={allowedDomains.join()}
              placeholder="Comma seperated domains"
              className="grow bg-transparent min-h-24 outline-none"
            />
          </label>
        </div>
        <div className="card-actions justify-end">
          <button
            className="btn btn-primary mt-8"
            onClick={() =>
              updateTenant({
                tenant,
                tenantPayload: {
                  allowedDomains: allowedDomains.map((domain) => domain.trim()),
                },
              })
            }
          >
            Save
          </button>
        </div>
        <Toast isVisible={isVisible} message={message} type={type} />
      </div>
    </div>
  );
};
