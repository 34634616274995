const { REACT_APP_API_BASE } = process.env;

export const tokenVerify = ({ token }) => {
  return async () => {
    const response = await fetch(`${REACT_APP_API_BASE}/token-verify`, {
      method: "POST",
      body: JSON.stringify({ token }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const parsedResponse = await response.json();

    if (!response.ok) {
      throw parsedResponse;
    }

    return parsedResponse;
  };
};

export const initiateAddDevice = ({ token }) => {
  return async (payload) => {
    const response = await fetch(`${REACT_APP_API_BASE}/initiate-add-device`, {
      method: "POST",
      body: JSON.stringify({ ...payload, token }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const parsedResponse = await response.json();

    if (!response.ok) {
      throw parsedResponse;
    }

    return parsedResponse;
  };
};

export const verifyEmail = async (payload) => {
  const response = await fetch(`${REACT_APP_API_BASE}/verify-email`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const parsedResponse = await response.json();

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

export const loginUser = async (payload) => {
  localStorage.removeItem("tzToken");
  const response = await fetch(`${REACT_APP_API_BASE}/login`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const parsedResponse = await response.json();

  if (!response.ok) {
    throw parsedResponse;
  }

  localStorage.setItem("tzToken", parsedResponse.token);

  const location = window.location;
  const successRedirect = new URLSearchParams(location.search).get(
    "successRedirect",
  );

  if (successRedirect) {
    window.location.assign(
      `${successRedirect}?token=${encodeURIComponent(parsedResponse.token)}`,
    );

    return {};
  }

  return parsedResponse;
};

export const getNonce = ({ email, tenant }) => {
  return async () => {
    const challengeResponse = await fetch(
      `${REACT_APP_API_BASE}/get-nonce?email=${email}&tenant=${tenant}`,
    );
    const { challenge } = await challengeResponse.json();
    const challengeArrayBuffer = Uint8Array.from(atob(challenge), (c) =>
      c.charCodeAt(0),
    );

    return challengeArrayBuffer;
  };
};

export const getUser = ({ email, tenant }) => {
  return async () => {
    const user = await fetch(
      `${REACT_APP_API_BASE}/get-user?email=${email}&tenant=${tenant}`,
    );
    const userData = await user.json();

    if (!user.ok) {
      throw userData;
    }
    return userData;
  };
};

export const getUsers = ({ token, tenant }) => {
  return async () => {
    const user = await fetch(
      `${REACT_APP_API_BASE}/get-users?tenant=${tenant}`,
      {
        method: "POST",
        body: JSON.stringify({ token }),
      },
    );
    const userData = await user.json();

    if (!user.ok) {
      throw userData;
    }
    return userData;
  };
};

export const getTenant = ({ token, tenant }) => {
  return async () => {
    const user = await fetch(
      `${REACT_APP_API_BASE}/get-tenant?tenant=${tenant}`,
      {
        method: "POST",
        body: JSON.stringify({ token }),
      },
    );
    const userData = await user.json();

    if (!user.ok) {
      throw userData;
    }
    return userData;
  };
};

export const setUserData = ({ token }) => {
  return async (payload) => {
    const setUserResponse = await fetch(`${REACT_APP_API_BASE}/set-user`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...payload, token }),
    });

    const responseBody = await setUserResponse.json();

    if (!setUserResponse.ok) {
      throw responseBody;
    }

    return responseBody;
  };
};

export const registerUserDevice = ({ token }) => {
  return async (payload) => {
    const registrationResponse = await fetch(
      `${REACT_APP_API_BASE}/register-user`,
      {
        method: "POST",
        body: JSON.stringify({ ...payload, token }),
      },
    );

    const responseBody = await registrationResponse.json();

    if (!registrationResponse.ok) {
      throw responseBody;
    }

    return responseBody;
  };
};

export const createUpdateTenant = ({ token }) => {
  return async ({ tenant, tenantPayload }) => {
    const registrationResponse = await fetch(
      `${REACT_APP_API_BASE}/create-tenant?tenant=${tenant}`,
      {
        method: "POST",
        body: JSON.stringify({ token, tenant, tenantPayload }),
      },
    );

    const responseBody = await registrationResponse.json();

    if (!registrationResponse.ok) {
      throw responseBody;
    }

    return responseBody;
  };
};

export const deleteUser = ({ token }) => {
  return async ({ email, tenant }) => {
    const registrationResponse = await fetch(
      `${REACT_APP_API_BASE}/delete-user?email=${email}&tenant=${tenant}`,
      {
        method: "POST",
        body: JSON.stringify({ token }),
      },
    );

    const responseBody = await registrationResponse.json();

    if (!registrationResponse.ok) {
      throw responseBody;
    }

    return responseBody;
  };
};

export const deleteTenant = ({ token }) => {
  return async (payload) => {
    const registrationResponse = await fetch(
      `${REACT_APP_API_BASE}/delete-tenant`,
      {
        method: "DELETE",
        body: JSON.stringify({ ...payload, token }),
      },
    );

    const responseBody = await registrationResponse.json();

    if (!registrationResponse.ok) {
      throw responseBody;
    }

    return responseBody;
  };
};
