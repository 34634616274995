import { useMutation } from "@tanstack/react-query";
import { verifyEmail } from "data/queries";
import { useTenant } from "hooks/use-tenant";
import { useEffect, useState } from "react";

export const Verify = ({ verifyData: [email, hash] = [] }) => {
  const { tenant } = useTenant();
  const [hasVerified, setHasVerified] = useState(false);
  const { mutate: sendVerification, isLoading } = useMutation({
    mutationFn: verifyEmail,
    mutationKey: ["verifyEmail", hash],
    onSuccess: () => setHasVerified(true),
  });

  useEffect(() => {
    if (email && hash && tenant) {
      sendVerification({ email, tenant, hash });
    }
  }, [email, hash, sendVerification, tenant]);

  return (
    <div className="w-[320px] m-[0_auto] mt-[10vh] border border-1 p-4 rounded-xl shadow-xl text-center">
      <div className="my-8">
        <h2 className="text-5xl">Trust Zero</h2>
        <a
          className="text-xs"
          href="https://stagezero.uk/"
          target="_blank"
          rel="noreferrer"
        >
          by Stage Zero
        </a>
      </div>

      {isLoading && (
        <>
          <p>Verifying</p>
          <span className="loading loading-dots loading-sm"></span>
        </>
      )}
      {hasVerified && (
        <div role="alert" className="alert alert-success">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>Your email has been verified!</span>
        </div>
      )}
    </div>
  );
};
