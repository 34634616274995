export function isValidEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return emailRegex.test(email);
}

export function arrayBufferToBase64(buffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

// function base64urlDecode(str) {
//   // Replace URL-specific characters with base64 standard characters
//   let base64 = str.replace(/-/g, '+').replace(/_/g, '/');
//
//   // Pad the string with '=' to make its length a multiple of 4
//   while (base64.length % 4) {
//     base64 += '=';
//   }
//
//   // Decode base64 string
//   let decodedData = atob(base64);
//
//   return decodedData;
// }

export function base64urlToUint8Array(base64urlString) {
  // Base64url to Base64
  let padding = "==".slice(0, (4 - (base64urlString.length % 4)) % 4);
  let base64 = base64urlString.replace(/-/g, "+").replace(/_/g, "/") + padding;

  // Base64 to binary string
  let str = atob(base64);

  // Binary string to Uint8Array
  let uintArray = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    uintArray[i] = str.charCodeAt(i);
  }
  return uintArray;
}
