import "./App.css";
import { RegisterLogin } from "views/RegisterLogin";
import { Verify } from "views/Verify";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import { UserDashboard } from "views/UserDashboard";
import { useMutation } from "@tanstack/react-query";
import { tokenVerify } from "data/queries";

export const UserContext = createContext(null);

document.addEventListener("DOMContentLoaded", () => {
  const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

  const setTheme = (e) => {
    if (e.matches) {
      document.documentElement.setAttribute("data-theme", "synthwave");
    } else {
      document.documentElement.setAttribute("data-theme", "emerald");
    }
  };

  setTheme(darkModeMediaQuery);

  darkModeMediaQuery.addListener(setTheme);
});

function App() {
  const location = window.location;
  const [user, setUser] = useState();
  const theme = new URLSearchParams(location.search).get("theme");
  const verify = new URLSearchParams(location.search).get("verify");
  const addDevice = new URLSearchParams(location.search).get("addDevice");
  const successRedirect = new URLSearchParams(location.search).get(
    "successRedirect",
  );

  const token = localStorage.getItem("tzToken");
  const {
    mutate: verifyToken,
    status,
    isLoading: isLoadingVerify,
  } = useMutation({
    mutationFn: tokenVerify({ token }),
    mutationKey: ["tokenVerify", token],
    onSuccess: ({ user }) => {
      setUser(user);
      if (successRedirect) {
        window.location.assign(
          `${successRedirect}?token=${encodeURIComponent(token)}`,
        );
      }
    },
  });

  useEffect(() => {
    if (!user && token) {
      verifyToken();
    }

    if (user && token && window.location.pathname === "/" && !successRedirect) {
      window.location.assign(`/admin/my-devices`);
    }
  }, [user, verifyToken, token, successRedirect]);

  if (theme) document.documentElement.setAttribute("data-theme", theme);

  const ProtectedRoute = ({ children }) => {
    if (status === "error") {
      // Redirect to the root route if user is not set
      return <Navigate to="/" replace />;
    }

    return children;
  };

  return (
    <UserContext.Provider value={[{ token, ...user }, setUser]}>
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route
              path="/admin/*"
              element={
                <ProtectedRoute>
                  <UserDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/domain-not-allowed"
              element={<div>Redirect domain not allowed</div>}
            />
            <Route
              path="/"
              element={
                verify ? (
                  <Verify verifyData={verify.split(`,`)} />
                ) : (
                  <RegisterLogin
                    addDevice={addDevice?.split(",")}
                    isLoadingVerify={isLoadingVerify}
                  />
                )
              }
            />
          </Routes>
        </div>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
