import React, { useContext, useEffect, useState } from "react";
import { initiateAddDevice } from "data/queries";
import { useMutation } from "@tanstack/react-query";
import { QRCodeSVG } from "qrcode.react";
import { UserContext } from "App";
import { useTenant } from "hooks/use-tenant";

export const AddDevice = () => {
  const { tenant } = useTenant();
  const [{ token, ...authedUser }] = useContext(UserContext);
  const [addDeviceLink, setAddDeviceLink] = useState();

  const { mutate: sendAddDeviceEmail } = useMutation({
    mutationFn: initiateAddDevice({ token }),
    mutationKey: ["initiateAddDevice", authedUser?.email],
    onSuccess: (addDeviceResponse) => {
      setAddDeviceLink(addDeviceResponse.addDeviceLink);
    },
  });

  useEffect(() => {
    sendAddDeviceEmail({ email: authedUser.email, tenant });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="card w-full bg-base-300 text-neutral-content shadow-lg">
      <div className="card-body items-center text-center">
        <div className="flex flex-col items-center space-y-4 text-base-content">
          <p>Scan this QR code on the device you&apos;d like to add</p>
          <div className="border border-8 rounded-md shadow-xl">
            <QRCodeSVG value={addDeviceLink} />
          </div>
        </div>
      </div>
    </div>
  );
};
