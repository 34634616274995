import { useMutation } from "@tanstack/react-query";
import { UserContext } from "App";
import { TagSelector } from "components/tag-selector";
import { deleteUser, getUsers, setUserData } from "data/queries";
import { format } from "date-fns";
import { useTenant } from "hooks/use-tenant";
import React, { useContext, useEffect, useState } from "react";

export const UserList = () => {
  const [userToEdit, setUserToEdit] = useState();
  const [userTags, setUserTags] = useState([]);
  const [{ token, ...authedUser }] = useContext(UserContext);
  const { tenant, data: tenantData } = useTenant();

  const isAdmin =
    authedUser.isAdmin || tenantData?.createdBy === authedUser?.email;

  const { mutate: fetchUsers, data: tenantUsers } = useMutation({
    mutationFn: getUsers({ token, tenant }),
    mutationKey: ["getUsers", authedUser?.email],
  });

  const { mutate: updateUser } = useMutation({
    mutationFn: setUserData({ token }),
    mutationKey: ["setUserData", authedUser?.email],
    onSuccess: () => {
      fetchUsers();
    },
  });

  const { mutate: deleteUserMutation } = useMutation({
    mutationFn: deleteUser({ token }),
    mutationKey: ["deleteUser", authedUser?.email],
    onSuccess: () => {
      fetchUsers();
    },
  });

  useEffect(() => {
    if (!tenantUsers?.length) fetchUsers();
  }, [tenantUsers, fetchUsers]);

  return (
    <div>
      <ul className="bg-base-300 shadow overflow-hidden rounded-md w-full mx-auto">
        {tenantUsers?.map((user) => {
          return (
            <li key={user.email}>
              <div className="px-4 py-5 sm:px-6">
                <div className="grid grid-cols-6">
                  <div className="col-span-4">
                    <h3 className="text-sm md:text-lg leading-6 font-medium text-base-content">
                      {user.email}
                    </h3>
                  </div>
                  <div className="col-span-2">
                    <p className="max-w-2xl text-sm text-gray-500">
                      Created: {format(new Date(user.createdAt), "MM/dd/yyyy")}
                    </p>
                  </div>
                </div>
                <div className="mt-4 flex items-center justify-between">
                  <p className="text-sm font-medium text-gray-500">
                    {user.verified ? (
                      <span className="text-success">Verified</span>
                    ) : (
                      <span className="text-error">Not verified</span>
                    )}
                  </p>
                  <div className="flex space-x-2">
                    <button
                      className="btn btn-sm"
                      disabled={!isAdmin}
                      onClick={() => {
                        setUserToEdit(user);
                        document.getElementById("edit-user").showModal();
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-sm  btn-outline btn-error"
                      disabled={!isAdmin || user.email === authedUser.email}
                      onClick={() =>
                        deleteUserMutation({ email: user.email, tenant })
                      }
                    >
                      <span className="material-symbols-outlined">delete</span>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>

      <dialog id="edit-user" className="modal modal-bottom sm:modal-middle">
        <div className="modal-box space-y-4">
          {userToEdit && (
            <>
              <h3 className="font-bold text-lg">Edit {userToEdit.email}</h3>
              {/* eslint-disable-next-line */}
              <label className="input input-bordered flex items-center gap-2">
                <span>Email:</span>
                <input
                  type="text"
                  className="grow bg-transparent"
                  value={userToEdit?.email}
                />
              </label>

              {/* eslint-disable-next-line */}
              <label className="input input-bordered flex items-center gap-2">
                <span>Tenant Admin:</span>
                <input
                  type="checkbox"
                  checked={
                    userToEdit?.isAdmin ||
                    userToEdit?.email === tenantData?.createdBy
                  }
                  className="checkbox"
                />
              </label>

              {/* eslint-disable-next-line */}
              <label className="grid grid-cols-12 grid-flow-row border input-bordered rounded-lg inline-block gap-2 px-4">
                <span className="self-center col-span-2">Tags:</span>
                <div className="col-span-10">
                  <TagSelector
                    defaultValue={userToEdit.tags}
                    onChange={(selectedTags) => setUserTags(selectedTags)}
                    availableTags={Array.from(
                      new Set(
                        tenantUsers
                          ?.map(({ tags = [] }) => tags)
                          .flat(Infinity),
                      ),
                    )}
                  />
                </div>
              </label>
            </>
          )}
          <div className="modal-action">
            <form method="dialog">
              <button
                className="btn"
                onClick={() => {
                  setUserToEdit();
                }}
              >
                Close
              </button>
              <button
                className="btn btn-success"
                onClick={() => {
                  updateUser({
                    email: userToEdit?.email,
                    tenant,
                    tags: userTags,
                  });
                  setUserToEdit();
                }}
              >
                Save
              </button>
            </form>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </div>
  );
};
