import { UserContext } from "App";
import { useContext } from "react";
import { format } from "date-fns";

export const MyDevices = () => {
  const [{ devices = [] }] = useContext(UserContext);
  return (
    <div>
      <ul className="bg-base-300 shadow overflow-hidden sm:rounded-md w-full mx-auto">
        {devices.map(({ deviceName, deviceId, createdAt }) => (
          <li key={deviceId}>
            <div className="px-4 py-5 sm:px-6">
              <div className="flex items-center justify-between">
                <h3 className="text-lg leading-6 font-medium text-base-content">
                  {deviceName}
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Created: {format(new Date(createdAt), "MM/dd/yyyy")}
                </p>
              </div>
              <div className="mt-4 flex items-center justify-between">
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  <span>Device ID: {deviceId}</span>
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
