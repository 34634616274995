import React, { useCallback, useEffect, useState, useContext } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getNonce, getUser, loginUser, registerUserDevice } from "data/queries";
import {
  arrayBufferToBase64,
  isValidEmail,
  base64urlToUint8Array,
} from "helpers";
import { useTenant } from "hooks/use-tenant";
import { UserContext } from "App";
import { useNavigate } from "react-router-dom";
import { TrustZeroLogo } from "components/trust-zero-logo";
import { PulseLoader } from "react-spinners";

export const RegisterLogin = ({
  addDevice: [addDeviceEmail, addDeviceHash] = [],
  isLoadingVerify,
}) => {
  const [{ token, ...authedUser } = {}, setAuthedUser] =
    useContext(UserContext);
  const [userEmail, setUserEmail] = useState(addDeviceEmail);
  const [error, setError] = useState();
  const [isRegistrationInitiated, setIsRegistrationInitiated] = useState(false);
  const [isLoginInitiated, setIsLoginInitiated] = useState(false);
  const { tenant } = useTenant();
  const navigate = useNavigate();

  const { data: nonce, refetch: refetchNonce } = useQuery({
    queryFn: getNonce({ email: userEmail, tenant }),
    queryKey: [`nonce`],
    enabled: false,
  });

  const {
    data: { user } = {},
    refetch: refetchUser,
    isLoading: isLoadingFetchUser,
  } = useQuery({
    queryFn: getUser({ email: userEmail, tenant }),
    queryKey: [`user`],
    enabled: false,
  });

  const { mutate: loginAction, isLoading: isLoadingLogin } = useMutation({
    mutationFn: loginUser,
    mutationKey: [`loginUser`],
    onSuccess: ({ token }) => {
      setAuthedUser({ ...authedUser, token });
      navigate(`/admin/my-devices`);
    },
    onError: (error) => setError(error.message),
  });

  const { mutate: registerNewUser, isLoading: isLoadingRegister } = useMutation(
    {
      mutationFn: registerUserDevice({ token }),
      mutationKey: [`registerUser`],
      onSuccess: () => {
        refetchUser();
      },
      onError: (error) => setError(error.message),
    },
  );

  const fetchNonceAndUser = useCallback(async () => {
    if (isValidEmail(userEmail)) {
      await refetchNonce();
      await refetchUser();
    }
  }, [userEmail, refetchNonce, refetchUser]);

  const handleRegistration = useCallback(async () => {
    try {
      const newCredential = await navigator.credentials.create({
        publicKey: {
          rp: { name: "Trust Zero" },
          user: {
            id: Uint8Array.from(userEmail, (c) => c.charCodeAt(0)),
            name: userEmail,
            displayName: userEmail,
          },
          challenge: nonce,
          userVerification: "preferred",
          attestation: "direct",
          timeout: 60000,
          pubKeyCredParams: [
            { alg: -7, type: "public-key" },
            { alg: -257, type: "public-key" },
          ],
        },
      });

      const publicKey = arrayBufferToBase64(
        newCredential.response.getPublicKey(),
      );

      registerNewUser({
        tenant,
        deviceId: newCredential.id,
        type: newCredential.type,
        email: userEmail,
        isAdmin: false,
        addDeviceHash,
        publicKey,
      });
    } catch (error) {
      console.error("Error during registering:", error);
      setError(`There was an issue registering this device: ${error.message}`);
    }
  }, [nonce, userEmail, registerNewUser, addDeviceHash, tenant]);

  const handleLogin = useCallback(async () => {
    try {
      const assertion = await navigator.credentials.get({
        publicKey: {
          user: {
            id: Uint8Array.from(userEmail, (c) => c.charCodeAt(0)),
            name: userEmail,
            displayName: userEmail,
          },
          challenge: nonce,
          allowCredentials: user?.devices?.map(({ type, deviceId }) => ({
            type,
            id: base64urlToUint8Array(deviceId),
          })),
          authenticatorSelection: {
            userVerification: "preferred",
          },
          attestation: "direct",
          timeout: 60000,
        },
      });

      loginAction({
        tenant,
        email: userEmail,
        successRedirect: new URLSearchParams(window.location.search).get(
          "successRedirect",
        ),
        assertion: {
          clientDataJSON: arrayBufferToBase64(
            assertion.response.clientDataJSON,
          ),
          authenticatorData: arrayBufferToBase64(
            assertion.response.authenticatorData,
          ),
          signature: arrayBufferToBase64(assertion.response.signature),
          userHandle: assertion.response.userHandle
            ? arrayBufferToBase64(assertion.response.userHandle)
            : null,
        },
      });

      setAuthedUser(user);
    } catch (error) {
      console.error("Error during login:", error);
      setError(
        `There was an issue logging in with this device: ${error.message}`,
      );
    }
  }, [nonce, user, loginAction, userEmail, tenant, setAuthedUser]);

  useEffect(() => {
    if (nonce && isRegistrationInitiated) {
      handleRegistration();
      setIsRegistrationInitiated(false);
    }

    if (addDeviceHash && addDeviceEmail) {
      if (nonce) {
        handleRegistration();
      }

      if (!nonce) {
        refetchNonce();
      }
    }
  }, [
    nonce,
    refetchNonce,
    isRegistrationInitiated,
    handleRegistration,
    addDeviceEmail,
    addDeviceHash,
  ]);

  useEffect(() => {
    if (nonce && user && isLoginInitiated) {
      handleLogin();
      setIsLoginInitiated(false);
    }
  }, [nonce, isLoginInitiated, handleLogin, user]);

  const isLoadingAll =
    isLoadingFetchUser || isLoadingLogin || isLoadingRegister;

  return (
    <div className="w-[320px] m-[0_auto] mt-[10vh] border border-1 p-4 rounded-xl shadow-xl text-center">
      <div className="flex flex-col items-center mb-4">
        <div className="mb-4">
          <TrustZeroLogo width={80} height={80} />
        </div>

        <h2 className="text-5xl">Trust Zero</h2>
        <a
          className="text-xs"
          href="https://stagezero.uk/"
          target="_blank"
          rel="noreferrer"
        >
          by Stage Zero 🚀
        </a>
      </div>
      {user?.email && !user.verified ? (
        <div role="alert" className="alert alert-info">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="stroke-current shrink-0 w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <span>Please check your email for verification.</span>
        </div>
      ) : (
        <form
          onSubmit={async (e) => {
            e.preventDefault();

            await fetchNonceAndUser();
            setIsRegistrationInitiated(true);
          }}
          className={`
            grid grid-rows-2 gap-4 relative 
            ${isLoadingAll && "opacity-50"}`}
        >
          <input
            type="email"
            className="input input-bordered w-full"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            placeholder="Email"
          />
          <div className="grid grid-cols-2 gap-4">
            {isLoadingVerify ? (
              <div className="col-span-2">
                <PulseLoader color={"#36d7b7"} />
              </div>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={async (e) => {
                    e.preventDefault();

                    await fetchNonceAndUser();
                    setIsLoginInitiated(true);
                  }}
                >
                  Login
                </button>
                <button type="submit" className="btn btn-outline btn-secondary">
                  Register
                </button>
              </>
            )}
          </div>
          {error && <div className="alert alert-error">{error}</div>}
        </form>
      )}
    </div>
  );
};
