import React, { useContext, useEffect } from "react";
import { deleteTenant } from "data/queries";
import { useMutation } from "@tanstack/react-query";
import { useTenant } from "hooks/use-tenant";
import { Toast, useToast } from "components/toast";
import { UserContext } from "App";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { UserList } from "./UserList";
import { AddDevice } from "./AddDevice";
import { TenantDomains } from "./TenantDomains";
import { MyDevices } from "./MyDevices";
import gravatar from "gravatar";
import { TrustZeroLogo } from "components/trust-zero-logo";

export const UserDashboard = () => {
  const [{ token, ...authedUser }] = useContext(UserContext);
  const { tenant } = useTenant();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/admin") {
      navigate("/admin/my-devices");
    }

    if (!token) {
      navigate("/");
    }
  }, [token, location, navigate]);

  const { isVisible, message, type, showToast } = useToast();

  const { mutate: deleteCurrentTenant } = useMutation({
    mutationFn: deleteTenant({ token }),
    mutationKey: ["deleteTenant", authedUser?.email],
    onError: ({ message }) => showToast(message, "error"),
    onSuccess: () => {
      window.location.reload();
    },
  });

  const profileImage = gravatar.url(authedUser.email, {
    s: "30",
    r: "pg",
  });

  return (
    <div className="h-full w-full p-4">
      <div className="navbar border border-base-300 bg-base-100 shadow-navbar rounded-xl p-2 flex justify-between">
        <div>
          <div className="drawer w-auto mr-4">
            <input id="my-drawer" type="checkbox" className="drawer-toggle" />
            <div className="drawer-content">
              {/* eslint-disable-next-line */}
              <label htmlFor="my-drawer" className="btn drawer-button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="inline-block w-5 h-5 stroke-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </label>
            </div>
            <div className="drawer-side z-50">
              <label
                htmlFor="my-drawer"
                aria-label="close sidebar"
                className="drawer-overlay"
              ></label>
              <ul className="menu p-4 w-80 min-h-full bg-base-200 text-base-content text-2xl space-y-8">
                <li>
                  <a href="/admin/my-devices">My Devices</a>
                </li>
                <li>
                  <a href="/admin/users">Users</a>
                </li>
                <li>
                  <a href="/admin/add-device">Add device</a>
                </li>
                <li>
                  <a href="/admin/allowed-domains">Allowed Domains</a>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={async (e) => {
                      e.preventDefault();
                      deleteCurrentTenant({ tenant, user: authedUser.email });
                    }}
                  >
                    Delete Tenant
                  </button>
                </li>

                <li>
                  <button
                    onClick={async () => {
                      localStorage.removeItem("tzToken");
                      navigate("/");
                    }}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <h2 className="text-xl flex items-center space-x-2">
            <TrustZeroLogo width={30} height={30} />{" "}
            <span className="hidden md:block">Trust Zero: </span>
            <span>{tenant}</span>
          </h2>
        </div>
        <div className="mask mask-hexagon">
          <img src={profileImage} alt="Tailwind" />
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:h-full">
        <div className="p-6 w-full">
          <Routes>
            <Route path="my-devices" element={<MyDevices />} />
            <Route path="users" element={<UserList />} />
            <Route path="allowed-domains" element={<TenantDomains />} />
            <Route path="add-device" element={<AddDevice />} />
          </Routes>
        </div>
      </div>

      <Toast isVisible={isVisible} message={message} type={type} />
    </div>
  );
};
