import React, { useState, useEffect, useRef } from "react";

export const TagSelector = ({ availableTags, onChange, defaultValue = [] }) => {
  const [tags, setTags] = useState(defaultValue);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    setSuggestions(availableTags);
  }, [availableTags]);

  useEffect(() => {
    onChange(tags);
  }, [tags, onChange]);

  const removeTag = (tag) => {
    setTags(tags.filter((tagToRemove) => tag !== tagToRemove));
  };

  const addTag = (tag) => {
    if (!tags.includes(tag) && tag.trim() !== "") {
      setTags([...tags, tag]);
      setInputValue("");
    }
  };

  const onInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (!value) {
      setSuggestions(availableTags);
    } else {
      const filteredSuggestions = availableTags.filter((tag) =>
        tag.toLowerCase().includes(value.toLowerCase()),
      );
      setSuggestions([value, ...filteredSuggestions]);
    }
  };

  const onInputKeyDown = (e) => {
    if (e.key === "Enter" && inputValue) {
      addTag(inputValue);
      e.preventDefault();
    }

    if (e.key === "Backspace" && !inputValue && tags.length > 0) {
      removeTag(tags[tags.length - 1]);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-wrap gap-2 p-2 rounded">
        {tags.map((tag, index) => (
          <div
            key={index}
            className="flex items-center gap-2 bg-secondary px-2 py-1 rounded text-primary-content"
          >
            {tag}
            <button type="button" onClick={() => removeTag(tag)}>
              &times;
            </button>
          </div>
        ))}
        <input
          ref={inputRef}
          value={inputValue}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          className="outline-none bg-transparent"
          placeholder="Add a tag..."
        />
      </div>
      {inputValue && (
        <ul className="border border-secondary bg-base-100 rounded shadow z-50">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className="p-2 hover:bg-secondary hover:text-secondary-content cursor-pointer"
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addTag(suggestion);
                }}
              >
                {suggestion}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
