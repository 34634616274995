import * as React from "react";
export const TrustZeroLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 700 700"
    width={700}
    height={700}
    {...props}
  >
    <defs>
      <linearGradient
        gradientTransform="rotate(150, 0.5, 0.5)"
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="ffflux-gradient"
      >
        <stop stopColor="#c7d2fe" stopOpacity={1} offset="0%" />
        <stop stopColor="#d8b4fe" stopOpacity={1} offset="100%" />
      </linearGradient>
      <clipPath id="clip">
        <rect width={700} height={700} rx={150} ry={150} />
      </clipPath>
      <filter
        id="ffflux-filter"
        x="-10%"
        y="-10%"
        width="120%"
        height="120%"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feGaussianBlur
          stdDeviation={20}
          in="SourceGraphic"
          edgeMode="none"
          result="blur"
        />
        <feBlend mode="multiply" in="SourceGraphic" in2="blur" result="blend" />
      </filter>
    </defs>
    <g clipPath="url(#clip)">
      <rect
        width={700}
        height={700}
        fill="url(#ffflux-gradient)"
        filter="url(#ffflux-filter)"
        rx={150}
        ry={150}
      />
    </g>
    <g transform="translate(150, 120) scale(15)">
      <path
        fill="#ffffff"
        d="M13.5,0C4.563,0,0.292,5.047,0.114,5.262C0.041,5.352,0,5.464,0,5.581v10.054         c0,8.504,7.826,13.553,11.19,15.329c0.244,0.129,0.546,0.035,0.676-0.209c0.129-0.244,0.035-0.547-0.209-0.676         C7.677,27.978,1,23.308,1,15.634V5.771c0.702-0.755,4.632-4.597,12-4.765V31.5c0,0.276,0.224,0.5,0.5,0.5         c0.149,0,0.283-0.065,0.375-0.169C15.512,31.127,27,25.816,27,15.5v-10c0-0.117-0.041-0.23-0.115-0.319         c-0.148-0.179-3.714-4.375-11.141-5.124c-0.275-0.019-0.521,0.173-0.548,0.447c-0.028,0.275,0.173,0.52,0.447,0.548         C21.93,1.686,25.346,4.997,26,5.692V15.5c0,8.952-9.363,13.949-12,15.179V0.5C14,0.224,13.776,0,13.5,0z"
      />
    </g>
  </svg>
);
