import { useMutation } from "@tanstack/react-query";
import { UserContext } from "App";
import { getTenant } from "data/queries";
import { useContext, useEffect, useState } from "react";

export const useTenant = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [{ token }] = useContext(UserContext) || [{}];
  const [cachedTenantData, setCachedTenantData] = useState();
  const tenantSubDomain = window.location.host.split(".").at(0);
  const tenant = tenantSubDomain || "demo";

  const { mutate: fetchTenant } = useMutation({
    mutationFn: getTenant({ token, tenant }),
    mutationKey: ["getTenant", tenant],
    onError: () => setIsLoading(false),
    onSuccess: (data) => setCachedTenantData(data),
  });

  useEffect(() => {
    if (!cachedTenantData) {
      setIsLoading(true);
      fetchTenant();
    }

    setIsLoading(false);
  }, [cachedTenantData, fetchTenant]);

  return { tenant, data: cachedTenantData, isLoading };
};
