import React, { useCallback, useState } from "react";

export const useToast = (initialVisibility = false) => {
  const [isVisible, setIsVisible] = useState(initialVisibility);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("info");

  const showToast = useCallback((newMessage, newType = "info") => {
    setMessage(newMessage);
    setType(newType);
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 5000);
  }, []);

  const hideToast = useCallback(() => {
    setIsVisible(false);
  }, []);

  return { isVisible, message, type, showToast, hideToast };
};

export const Toast = ({ isVisible, message, type }) => {
  const animationClass = isVisible ? "animate-slideUp" : "animate-slideDown";

  return (
    <div className={`toast toast-end ${animationClass} fixed bottom-5 right-5`}>
      <div className={`alert alert-${type}`}>
        <span>{message}</span>
      </div>
    </div>
  );
};
